/* eslint-disable @typescript-eslint/no-explicit-any */
// readMoreUtils.ts
import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import rehypeParse from "rehype-parse";
import DOMPurify from "isomorphic-dompurify";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Blockquote from "@tiptap/extension-blockquote";
import Highlight from "@tiptap/extension-highlight";
import { Review } from "@/graphql/generated/types";
import Image from "@tiptap/extension-image";

/* --------------------------------------------------------------------------
   Create a proper inline "Read More" element node
-------------------------------------------------------------------------- */
const createReadMoreNode = () => ({
  type: "element",
  tagName: "span",
  properties: {
    className: ["read-more"],
    style: "cursor:pointer; text-decoration:underline; display:inline;"
  },
  children: [{
    type: "text",
    value: "Read More"
  }]
});

/* --------------------------------------------------------------------------
   Create an ellipsis text node (with a leading space).
   This node is plain text so it won’t be underlined.
-------------------------------------------------------------------------- */
const createEllipsisNode = () => ({
  type: "text",
  value: " …" // a space and three dots
});

/* --------------------------------------------------------------------------
   Shared “Read More” snippet (inline HTML)
-------------------------------------------------------------------------- */
const READ_MORE_HTML = ` ...<span class="read-more" style="cursor:pointer; text-decoration:underline;">Read More</span>`;

/* --------------------------------------------------------------------------
   Utility: Remove extra properties from nodes
   This function recursively removes properties (like "position" and "data")
   that can cause type conflicts when converting from mdast to hast.
-------------------------------------------------------------------------- */
function removePositions(node: any): any {
  if (typeof node !== "object" || node === null) return node;
  const newNode = {
    ...node
  };
  if ("position" in newNode) {
    delete newNode.position;
  }
  if ("data" in newNode) {
    delete newNode.data;
  }
  if (newNode.children && Array.isArray(newNode.children)) {
    newNode.children = newNode.children.map(removePositions);
  }
  return newNode;
}

/* --------------------------------------------------------------------------
   AST Truncation for Markdown (using mdast from remark)
-------------------------------------------------------------------------- */

/**
 * Recursively traverses mdast nodes to count characters.
 * When the limit is reached, truncates text nodes accordingly.
 */
export function truncateAstNodes(nodes: any[], limit: number, currentCount: number = 0): {
  nodes: any[];
  count: number;
  reached: boolean;
} {
  const resultNodes = [];
  let reached = false;
  for (const node of nodes) {
    if (reached) break;
    if (node.type === "text") {
      const textLength = node.value.length;
      if (currentCount + textLength <= limit) {
        resultNodes.push(node);
        currentCount += textLength;
      } else {
        const remaining = limit - currentCount;
        if (remaining > 0) {
          resultNodes.push({
            ...node,
            value: node.value.slice(0, remaining)
          });
        }
        reached = true;
        break;
      }
    } else if (node.children && Array.isArray(node.children)) {
      const newNode = {
        ...node
      };
      const result = truncateAstNodes(node.children, limit, currentCount);
      newNode.children = result.nodes;
      resultNodes.push(newNode);
      currentCount = result.count;
      if (result.reached) {
        reached = true;
        break;
      }
    } else {
      resultNodes.push(node);
    }
  }
  return {
    nodes: resultNodes,
    count: currentCount,
    reached
  };
}

/**
 * Appends the inline “Read More” snippet to the last paragraph node.
 */
export function appendReadMoreLink(ast: any): any {
  if (!ast.children || ast.children.length === 0) {
    ast.children = [{
      type: "paragraph",
      children: [{
        type: "html",
        value: READ_MORE_HTML
      }]
    }];
  } else {
    let appended = false;
    for (let i = ast.children.length - 1; i >= 0; i--) {
      const node = ast.children[i];
      if (node.type === "paragraph" && node.children && node.children.length > 0) {
        node.children.push({
          type: "html",
          value: READ_MORE_HTML
        });
        appended = true;
        break;
      }
    }
    if (!appended) {
      ast.children.push({
        type: "paragraph",
        children: [{
          type: "html",
          value: READ_MORE_HTML
        }]
      });
    }
  }
  return ast;
}

/**
 * Processes Markdown text to produce a truncated HTML snippet.
 * Uses `removePositions` to “clean” the AST so that remark‑rehype
 * doesn’t complain about mismatched types.
 */
export function getTruncatedMarkdownHtml(markdown: string, limit: number): {
  html: string;
  truncated: boolean;
} {
  // Parse markdown into an mdast.
  const processor = unified().use(remarkParse);
  const ast = processor.parse(markdown);
  // Clean the AST to remove extra properties that may cause type conflicts.
  const cleanAst = removePositions(ast);

  // Truncate the AST.
  const {
    nodes: truncatedNodes,
    reached
  } = truncateAstNodes(cleanAst.children, limit, 0);
  const newAst = {
    type: cleanAst.type,
    children: truncatedNodes
  };
  const finalAst = reached ? appendReadMoreLink(newAst) : newAst;

  // **Fix:** Run the transformation so the mdast is converted to a hast.
  const processedTree = unified().use(remarkRehype, {
    allowDangerousHtml: true
  }).use(rehypeRaw).runSync(finalAst);

  // Now stringify the hast tree.
  const html = unified().use(rehypeStringify).stringify(processedTree);
  return {
    html: DOMPurify.sanitize(html),
    truncated: reached
  };
}

/**
 * Fully converts Markdown to HTML (for the expanded view).
 */
export function convertMarkdownToHtml(markdown: string): string {
  const ast = unified().use(remarkParse).parse(markdown);
  const cleanAst = removePositions(ast);
  // Run the transformation to convert to hast.
  const processedTree = unified().use(remarkRehype, {
    allowDangerousHtml: true
  }).use(rehypeRaw).runSync(cleanAst);
  const html = unified().use(rehypeStringify).stringify(processedTree);
  return DOMPurify.sanitize(html);
}
/* --------------------------------------------------------------------------
   AST Truncation for HTML (for reviews from Tiptap)
   (This works on a hast AST produced by rehype-parse)
-------------------------------------------------------------------------- */
function truncateHastNodes(nodes: any[], limit: number, currentCount = 0): {
  nodes: any[];
  count: number;
  reached: boolean;
} {
  const resultNodes = [];
  let reached = false;
  for (const node of nodes) {
    if (reached) break;
    if (node.type === "text") {
      const textLength = node.value.length;
      if (currentCount + textLength <= limit) {
        resultNodes.push(node);
        currentCount += textLength;
      } else {
        const remaining = limit - currentCount;
        if (remaining > 0) {
          resultNodes.push({
            ...node,
            value: node.value.slice(0, remaining)
          });
        }
        reached = true;
        break;
      }
    } else if (node.children && Array.isArray(node.children)) {
      const newNode = {
        ...node
      };
      const result = truncateHastNodes(node.children, limit, currentCount);
      newNode.children = result.nodes;
      resultNodes.push(newNode);
      currentCount = result.count;
      if (result.reached) {
        reached = true;
        break;
      }
    } else {
      resultNodes.push(node);
    }
  }
  return {
    nodes: resultNodes,
    count: currentCount,
    reached
  };
}

/* --------------------------------------------------------------------------
   Append the inline "Read More" node with an ellipsis.
   We insert an ellipsis node (with a leading space) and then the "Read More" node.
   They are appended to the end of the last inline element.
-------------------------------------------------------------------------- */
function appendReadMoreToHast(ast: any): any {
  const ellipsisNode = createEllipsisNode();
  const readMoreNode = createReadMoreNode();
  if (!ast.children || ast.children.length === 0) {
    ast.children = [ellipsisNode, readMoreNode];
  } else {
    const lastIndex = ast.children.length - 1;
    const lastNode = ast.children[lastIndex];
    if (lastNode.type === "element" && lastNode.tagName === "p") {
      // Append to the paragraph's children.
      lastNode.children = lastNode.children || [];
      lastNode.children.push(ellipsisNode, readMoreNode);
    } else if (lastNode.type === "text") {
      // Wrap the text node in a span and append the ellipsis and read-more inline.
      ast.children[lastIndex] = {
        type: "element",
        tagName: "span",
        properties: {},
        children: [lastNode, ellipsisNode, readMoreNode]
      };
    } else if (lastNode.type === "element") {
      // Assume it's inline and append.
      lastNode.children = lastNode.children || [];
      lastNode.children.push(ellipsisNode, readMoreNode);
    } else {
      // Fallback: simply push the nodes.
      ast.children.push(ellipsisNode, readMoreNode);
    }
  }
  return ast;
}

/**
 * Processes an HTML string (from Tiptap) to produce a truncated version.
 */
export function getTruncatedHtml(html: string, limit: number): {
  html: string;
  truncated: boolean;
} {
  // Parse the HTML into a hast AST.
  const tree = unified().use(rehypeParse, {
    fragment: true
  }).parse(html);
  const {
    nodes: truncatedNodes,
    reached
  } = truncateHastNodes(tree.children, limit, 0);
  tree.children = truncatedNodes;
  if (reached) {
    appendReadMoreToHast(tree);
  }
  const truncatedHtml = unified().use(rehypeStringify).stringify(tree);
  return {
    html: DOMPurify.sanitize(truncatedHtml),
    truncated: reached
  };
}
export const getHtmlContent = (content: Review["content"]) => {
  // // In your readMoreUtils.ts or a global polyfill file:
  // if (typeof window === "undefined") {
  //   // Minimal polyfill – add any properties you might actually need.
  //   ;(global as any).window = {}
  // }

  return generateHTML(content, [StarterKit.configure({
    bulletList: false,
    orderedList: false,
    listItem: false,
    blockquote: false
  }), Underline, Highlight.configure({
    multicolor: false,
    HTMLAttributes: {
      style: "background-color: #0b7285; color: inherit;"
    }
  }), ListItem, BulletList, OrderedList, Image, Blockquote]);
};