// ReviewReadMore.tsx
"use client";

import React, { useState, useMemo } from "react";
import { getHtmlContent, getTruncatedHtml } from "@/utils/readMoreUtils";
import { useResponsive } from "@/hooks/useResponsive";
import { cn } from "@/lib/utils";
import { Review } from "@/graphql/generated/types";
interface ReviewReadMoreProps {
  content: Review["content"]; // Tiptap JSON content
  mobileLength?: number;
  desktopLength?: number;
  className?: string;
  // Optionally, precomputed values from the server:
  preTruncatedHtml?: string;
  preFullHtml?: string;
}
const ReviewReadMore: React.FC<ReviewReadMoreProps> = ({
  content,
  mobileLength = 330,
  desktopLength = 794,
  className,
  preTruncatedHtml,
  preFullHtml
}) => {
  const {
    isTablet
  } = useResponsive();
  const displayLength = isTablet ? mobileLength : desktopLength;
  const [isExpanded, setIsExpanded] = useState(false);

  // Convert the Tiptap JSON content to HTML.
  const htmlContent = getHtmlContent(content);

  // Compute the truncated version.
  const truncatedResult = useMemo(() => preTruncatedHtml ? {
    html: preTruncatedHtml,
    truncated: true
  } : getTruncatedHtml(htmlContent, displayLength), [htmlContent, displayLength, preTruncatedHtml]);
  // Expanded view is the full HTML.
  const expandedHtml = preFullHtml ? preFullHtml : htmlContent;
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isTablet) {
      setIsExpanded(true);
      return;
    }
    const target = e.target as HTMLElement;
    if (target.classList.contains("read-more")) {
      setIsExpanded(true);
    }
  };
  const htmlToRender = isExpanded ? expandedHtml : truncatedResult.html;
  return <div onClick={handleClick} className={cn("text-sm font-normal whitespace-pre-line sm:text-base lg:leading-[22px] text-[#E6E6FA]/90", className)} dangerouslySetInnerHTML={{
    __html: htmlToRender
  }} style={{
    wordWrap: "break-word",
    wordBreak: "break-word",
    overflowWrap: "break-word"
  }} data-sentry-component="ReviewReadMore" data-sentry-source-file="ReviewReadMore.tsx" />;
};
export default ReviewReadMore;