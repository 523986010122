"use client";

import React from "react";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from "@/components/ui/dropdown-menu";
import { Button, buttonVariants } from "@/components/ui/button";
// import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu"
import ReportButton from "../shared/ReportButton";
import Link from "next/link";
import { Loader2, LucideEdit3 } from "lucide-react";
import { useUser } from "@/contexts/user";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader
// DialogTitle,
} from "@/components/ui/dialog";
import { useMutation } from "@apollo/client";
import { BookReviewsBySlugDocument, DeleteReviewDocument, SortBy } from "@/graphql/generated/types";
import { HiOutlineTrash } from "react-icons/hi2";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { REVIEWS_PER_PAGE } from "@/constants";
// import WarningIcon from "../icons/WarningIcon"
import { cn } from "@/lib/utils";
interface ReviewActionsMenuProps {
  id: string;
  isMine: boolean;
  shareUrl: string;
  reviewAuthorName: string;
  bookSlug: string;
  redirectToBookPage?: boolean;
}
const ReviewActionsMenu: React.FC<ReviewActionsMenuProps> = ({
  id,
  isMine,
  shareUrl,
  reviewAuthorName,
  bookSlug,
  redirectToBookPage
}) => {
  const page = useSearchParams().get("page");
  const pathname = usePathname();
  const currentPage = page ? Number(page) : 1;
  const sortBy = useSearchParams().get("sortBy") ?? SortBy.MostLiked;
  const {
    user
  } = useUser();
  const [deleteReview, {
    loading
  }] = useMutation(DeleteReviewDocument);
  const router = useRouter();
  const isTouchDevice = typeof window !== "undefined" && "ontouchstart" in window;
  const [DialogOpen, setDialogOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const handleDeleteReview = async () => {
    try {
      await deleteReview({
        variables: {
          id
        },
        optimisticResponse: {
          __typename: "RootMutationType",
          deleteReview: true // if your schema returns Boolean
        },
        refetchQueries: [{
          query: BookReviewsBySlugDocument,
          variables: {
            slug: bookSlug,
            page: currentPage,
            pageSize: REVIEWS_PER_PAGE,
            sortBy: sortBy as SortBy
          }
        }],
        awaitRefetchQueries: true
        // update: (cache, { data }) => {
        //   if (!data?.deleteReview) return
        //   const existing = cache.readQuery({
        //     query: MyReviewForBookDocument,
        //     variables: { slug: bookSlug as string },
        //   })

        //   if (!existing?.bookBySlug) return

        //   cache.writeQuery({
        //     query: MyReviewForBookDocument,
        //     variables: { slug: bookSlug as string },
        //     data: {
        //       bookBySlug: {
        //         ...existing.bookBySlug,
        //         myReview: null,
        //       },
        //     },
        //   })

        //   const existingReviewsBookPage = cache.readQuery({
        //     query: BookReviewsBySlugDocument,
        //     variables: {
        //       slug: bookSlug,
        //       page: 1,
        //       pageSize: 5,
        //       sortBy,
        //     },
        //   }) as any

        //   console.log(existingReviewsBookPage, "existingReviewsBookPage")
        //   console.log(cache.extract())

        //   if (!existingReviewsBookPage?.bookBySlug?.reviews?.items) return

        //   const filteredItems =
        //     existingReviewsBookPage.bookBySlug.reviews.items.filter(
        //       (r: any) => r?.id !== vars.id
        //     )

        //   cache.writeQuery({
        //     query: BookReviewsBySlugDocument,
        //     variables: {
        //       slug: bookSlug,
        //       page: 1,
        //       pageSize: 5,
        //       sortBy,
        //     },
        //     data: {
        //       bookBySlug: {
        //         ...existingReviewsBookPage.bookBySlug,
        //         reviews: {
        //           ...existingReviewsBookPage.bookBySlug.reviews,
        //           items: filteredItems,
        //         },
        //       },
        //     },
        //   })
        // },
      });
      setDialogOpen(false);
    } catch (error) {
      console.log(error);
    }
    if (redirectToBookPage) {
      router.push(`/books/${bookSlug}`);
    }
  };
  isMine = !!user && isMine;
  const editReviewUrl = `${shareUrl.split("/").slice(0, -1).join("/")}/new`;
  if (pathname === "/" && isMine) return;
  return <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen} data-sentry-element="DropdownMenu" data-sentry-source-file="ReviewActionsMenu.tsx">
        <DropdownMenuTrigger {...isTouchDevice ? {
        onPointerDown: e => e.preventDefault(),
        onClick: () => setDropdownOpen(!dropdownOpen)
      } : undefined} asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="ReviewActionsMenu.tsx">
          <Button variant="link" className={cn("p-0 size-9 -ml-2 rounded-full cursor-pointer")} data-sentry-element="Button" data-sentry-source-file="ReviewActionsMenu.tsx">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ReviewActionsMenu.tsx">
              <path d="M2.85345 8.19828C2.85345 7.89357 2.60643 7.64655 2.30172 7.64655C1.99702 7.64655 1.75 7.89357 1.75 8.19828C1.75 8.50298 1.99702 8.75 2.30172 8.75C2.60643 8.75 2.85345 8.50298 2.85345 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
              <path d="M8.36907 8.19828C8.36907 7.89357 8.12206 7.64655 7.81735 7.64655C7.51264 7.64655 7.26562 7.89357 7.26562 8.19828C7.26562 8.50298 7.51264 8.75 7.81735 8.75C8.12206 8.75 8.36907 8.50298 8.36907 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
              <path d="M13.8847 8.19828C13.8847 7.89357 13.6377 7.64655 13.333 7.64655C13.0283 7.64655 12.7812 7.89357 12.7812 8.19828C12.7812 8.50298 13.0283 8.75 13.333 8.75C13.6377 8.75 13.8847 8.50298 13.8847 8.19828Z" stroke="white" strokeWidth="2.22997" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
            </svg>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="center" sideOffset={0} className="w-[120px] border border-[#bdbdbd]/10 bg-[#1d1e2f] p-0" data-sentry-element="DropdownMenuContent" data-sentry-source-file="ReviewActionsMenu.tsx">
          {/* <DropdownMenuArrow
            height={18}
            width={30}
            className="fill-[#1d1e2f]"
           /> */}

          {pathname !== "/" && isMine && <Link href={editReviewUrl ?? ""} className={cn(buttonVariants({
          variant: "secondary"
        }), "w-full bg-transparent h-full pl-0 pr-[37px] flex items-center text-sm gap-[13px] py-4 cursor-pointer")}>
              <LucideEdit3 size={18} />
              Edit
            </Link>}

          {pathname !== "/" && isMine && <Button variant="secondary" onClick={() => {
          setDialogOpen(true);
          setDropdownOpen(false);
        }} className="w-full bg-transparent text-[#f94441] h-full pl-3.5 pr-[30px] flex items-center justify-center text-sm gap-[13px] py-4 cursor-pointer">
              <HiOutlineTrash size={18} />
              Delete
            </Button>}

          {/* Report Review Dialog */}

          {!isMine && <ReportButton reportType="review" reportedEntityName={reviewAuthorName} reportedEntityUrl={shareUrl} />}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={DialogOpen} onOpenChange={setDialogOpen} data-sentry-element="Dialog" data-sentry-source-file="ReviewActionsMenu.tsx">
        <DialogContent hideCloseButton className="max-sm:max-w-[336px] gap-0 sm:max-w-[437px] p-0 border-none rounded-[12px] bg-[#10111e]" data-sentry-element="DialogContent" data-sentry-source-file="ReviewActionsMenu.tsx">
          <DialogHeader className="flex border-b border-[#1E1F2B] flex-row pl-6 pr-[21px] py-3.5 justify-between space-y-0 items-center gap-2" data-sentry-element="DialogHeader" data-sentry-source-file="ReviewActionsMenu.tsx">
            {/* <WarningIcon /> */}
            <p className="w-fit tracking-[-0.01em] max-sm:p-0 text-lg text-white font-bold sm:font-semibold">
              Delete Review?
            </p>

            <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="ReviewActionsMenu.tsx">
              <Button type="button" variant="link" className="p-3 bg-transparent border border-[#1e1f2b] hover:bg-[#1e1f2b] transition-colors duration-200 rounded-full font-normal text-white flex items-center size-11" data-sentry-element="Button" data-sentry-source-file="ReviewActionsMenu.tsx">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ReviewActionsMenu.tsx">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.0237 3.97792C16.3966 4.35081 16.3966 4.9554 16.0237 5.32829L5.32927 16.0227C4.95637 16.3956 4.35179 16.3956 3.97889 16.0227C3.60599 15.6498 3.60599 15.0453 3.97889 14.6723L14.6733 3.97792C15.0463 3.60502 15.6508 3.60502 16.0237 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M3.97889 3.97792C4.35179 3.60502 4.95637 3.60502 5.32927 3.97792L16.0237 14.6723C16.3966 15.0453 16.3966 15.6498 16.0237 16.0227C15.6508 16.3956 15.0463 16.3956 14.6733 16.0227L3.97889 5.32829C3.60599 4.9554 3.60599 4.35081 3.97889 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="ReviewActionsMenu.tsx" />
                </svg>
              </Button>
            </DialogClose>
          </DialogHeader>
          <div className="px-[26px] py-6">
            <p className="text-sm font-normal text-[#868F9D]">
              Are you sure you want to delete this review?
            </p>

            <DialogFooter className="flex sm:space-x-0 gap-2 max-sm:flex-row w-full mt-4 justify-end items-center" data-sentry-element="DialogFooter" data-sentry-source-file="ReviewActionsMenu.tsx">
              <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="ReviewActionsMenu.tsx">
                <Button type="button" variant="secondary" className="px-4 py-3 bg-white/[6%] text-sm font-normal text-white flex items-center gap-2 rounded-[8px] h-[41px]" data-sentry-element="Button" data-sentry-source-file="ReviewActionsMenu.tsx">
                  Cancel
                </Button>
              </DialogClose>
              <Button variant="destructive" className="px-4 py-3 text-white ml-0 w-fit min-w-[74px] text-sm font-normal flex items-center gap-2 rounded-[8px] h-[41px]" onClick={handleDeleteReview} disabled={loading} data-sentry-element="Button" data-sentry-source-file="ReviewActionsMenu.tsx">
                {loading ? <Loader2 className="animate-spin" size={16} /> : "Delete"}
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>;
};
export default ReviewActionsMenu;